body {
  margin: 0;
  padding: 2rem 0;
  min-height: 100vh;
  background: #9796f0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #fbc7d4,
    #9796f0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #fbc7d4,
    #9796f0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
